@mixin button-reset() {
	display: inline-block;
	cursor: pointer;
	border: 0;
	background-color: transparent;
	padding: 0;
	margin: 0;
}

button {
	@include button-reset();
}

.btn {
	@include button-reset();
	@include transition(opacity, .4s, ease-in-out);

	&.loader {
		position: relative;
		min-width: 65px;

		[class*="loader_default"] {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
		}

		&.istext {
			[class*="loader_dotTyping"] {
				margin-left: 10px;
			}

			span {
				padding-left: 15px;
			}
		}
	}

	&:not(.reset) {
		font-weight: $font-medium;
		@include border-radius(5px);
		font-size: 14px;
		line-height: 18px;
		padding: 11px;
		color: $white;

		&.big {
			padding: 16px 30px;
		}

		&.longer {
			padding: 11px 20px;
		}
	}

	&.disabled {
		@include opacity(0.4);
		pointer-events: none;
		cursor: default;
	}

	&.smaller {
		padding: 11px;
	}

	&:hover {
		@include opacity(0.9);
	}

	&.p100 {
		width: 100%;
	}

	&.white-text {
		color: $white;
	}

	&.primary-text {
		color: $primary-color;
	}

	&.default-text {
		color: $text-gray;
	}

	&.light-text {
		color: $text-gray-light;
	}

	&.default {
		background-color: $btn-default;
	}

	&.dark {
		background-color: $btn-dark;
	}

	&.secondary {
		background-color: $color-secondary;
	}

	&.gray-light {
		background-color: $border-color-ligher;
		color: $gray-dark;
	}

	&.white {
		background-color: $white;
		color: $primary-color;

		&.color-gray-dark {
			color: $gray-dark;
		}
	}

	&.primary {
		background-color: $btn-primary;
		text-decoration: none;
	}

	&.facebook {
		background-color: $facebook-color;
	}

	&.success {
		background-color: $success-color;
	}

	&.danger {
		background-color: $danger-color;
	}

	&.outline {
		border: 1px solid $btn-default;
		background-color: transparent;

		&.default {
			color: $text-gray-light;
		}

		&.lightgray {
			border-color: $border-color-light;
			color: $text-gray-light;
		}

		&.primary {
			border-color: $primary-color;
			color: $primary-color;
		}

		&.dark {
			border-color: $gray-dark;
			color: $gray-dark;
		}
	}

	&.plain {
		background-color: transparent;
		padding: 0;
		font-weight: 400;

		&.primary {
			color: $primary-color;
		}

		&.mk {
			color: $mk-color;
		}
	}

	&.i {
		@include flexbox();
		@include align-items(center);

		&:not(.left) {
			@include justify-content(center);
		}

		&:not(.nomarginright) {
			i:first-child:not(.after) {
				margin-right: 10px;
			}
		}

		i.after {
			margin-left: 10px;
		}
	}
}

.toggler {
	display: flex;
	flex-direction: column;
	z-index: 1;
	position: relative;
	width: 24px;
	height: 20px;
	margin: 0 !important;

	@include media-above (md) {
		display: none;
	}

	span {
		width: 24px;
		height: 3px;
		background-color: #000;
		position: absolute;
		left: 0;

		&:nth-child(1) {
			top: 0;
			transition: 0.1s ease-in-out;
		}

		&:nth-child(2),
		&:nth-child(3) {
			top: 8px;
			transition: 0.2s ease-in-out;
		}

		&:nth-child(4) {
			top: 16px;
			transition: 0.1s ease-in-out;
		}
	}

	&.open {
		span {
			&:nth-child(1),
			&:nth-child(4) {
				opacity: 0;
			}

			&:nth-child(2) {
				transform: rotate(45deg);
			}

			&:nth-child(3) {
				transform: rotate(-45deg);
			}
		}
	}
}

// Animated a

.aa {
	i {
		position: relative;
		right: -7px;
		top: 2px;
		@include transition(right, .2s);
	}

	&:hover {
		i {
			right: -10px;
		}
	}
}
